<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0" />
            <span class="user-status">{{ $store.getters.getUser.name }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/13-small.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          :to="{ name: 'user-profile' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>{{ $t('Profile') }}</span>
        </b-dropdown-item>

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Chat</span>
        </b-dropdown-item> -->

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout()"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t('Logout') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <div class="suernameInfo-container">
        <span class="user-firstname">{{ userInfo.contactFirstName }}</span>   <span class="user-lastname">{{ userInfo.contactLastName }}</span>
      </div>
    </b-navbar-nav>
  </div>
</template>

<script>
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Locale from './Locale.vue'
import useJwt from '@/auth/useJwt'

export default {
  components: {
    // Navbar Components
    DarkToggler,
    Locale,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => { },
    },
  },
  data() {
    return {
      userData: '',
      userInfo: '',
    }
  },
  computed: {
    isAdmin() {
      return this.$store.state.app.userRole === 'Admin'
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
  },
  mounted() {
    this.userData = JSON.parse(localStorage.getItem('userInfo'))
    this.userInfo = this.userData.partnerInfo.data
  },

  methods: {
    logout() {
      useJwt.removeToken()
      // useJwt.removeUserData()
      localStorage.removeItem('locale')
      localStorage.removeItem('userInfo')
      window.location.reload()
    },
  },
}
</script>
<style scoped lang="scss">
.user-lastname {
  margin-inline-start: 2px;
}
.suernameInfo-container {
  direction: initial;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
